import React from 'react';

import config from '../../config';

export default function Footer() {
  return (
    <header id="header">
      <img src={require('../assets/images/blueberries.svg')}/>
      <h1>{config.heading}</h1>
      <p>{config.subHeading}</p>
    </header>
  );
}
